
export default {
	name: "CoreBlockButton",
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	computed: {
		icon() {
			return this.settings?.icon;
		},
	},
};
